import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ArticlePreview from '../components/article-preview';
import { AdUnit } from '../components/adunit';
import Places from '../components/places';
import Offer from '../components/offer';
import extractTransitColors from '../extract-transit-colors';
import filterUnapprovedEntries from '../filter-unapproved-entries';
import formatRichText from '../format-rich-text';
import fixAssetUrl from '../fix-asset-url';
import { useArticleProgress } from '../hooks/use-article-progress';
import { getRandomHeroImageByCategory } from '../utils';
import { LocationTags } from '../components/location-tags';

export default ({ data }) => {
  useArticleProgress();

  const { contentfulHomePage, article: post } = data;
  const {
    title,
    slug,
    heroImage,
    categories,
    subcategories,
    photoCredit,
    authorName,
    publishDate,
    partnerLink,
    partnerLogo,
  } = post;

  const { articlesAdUnits: ads } = contentfulHomePage || {};

  // TODO: remove the OR filtering once `section` is deprecated
  // use the first category to filter instead of the deprecated `section`
  const firstCategory = categories
    ? categories[0].slug
    : post.section
    ? post.section.slug
    : 'Uncategorized';

  const relatedPosts = filterUnapprovedEntries(
    data.allArticles.edges
      .map(({ node }) => node)
      .map(a =>
        a.id !== post.id &&
        a.categories?.filter(c => c.slug === firstCategory).length
          ? a
          : null
      )
      .filter(n => n)
  ).slice(0, 2);

  const image = heroImage
    ? heroImage
    : getRandomHeroImageByCategory(categories, contentfulHomePage.categories);

  const primaryTransitColor = extractTransitColors(post)[0];

  let partnerImage = null;
  if (partnerLogo) {
    if (partnerLink) {
      partnerImage = (
        <a href={partnerLink} target="_blank" rel="noreferrer">
          <img src={fixAssetUrl(partnerLogo.file?.url)} alt="Partner Logo" />
        </a>
      );
    } else {
      partnerImage = (
        <img src={fixAssetUrl(partnerLogo.file?.url)} alt="Partner Logo" />
      );
    }
  }

  return (
    <Layout
      title={post.title}
      canonicalUrl={post.canonicalUrl}
      description={post.description?.childMarkdownRemark?.rawMarkdownBody}
      image={fixAssetUrl(post.heroImage?.fixed?.src)}
      mainClass="article-page"
      currentSection={firstCategory}
      {...(firstCategory !== 'about-us' && {
        // backToLink: `/${firstCategory}`,
        backToLink: '/articles',
        backToLabel: 'Back to Guides',
      })}
    >
      <article className={`article-details ${ads?.length ? '' : 'no-border'}`}>
        <div className="article-header">
          {slug != 'about-mta-away' && (
            <ul className="article__meta">
              {categories?.map(({ title }) => (
                <li key={title}>{title}</li>
              ))}
              {subcategories?.map(({ title }) => (
                <li key={title}>{title}</li>
              ))}
            </ul>
          )}
          <div className="article-header__title">
            <h1>{title || 'Untitled Article'}</h1>
            <div
              className="article-progress"
              style={{ color: `${primaryTransitColor}` }}
            />
          </div>
          <img
            className="article-header__image"
            alt={image.fixed.title}
            src={fixAssetUrl(image.fixed.src)}
            style={{ borderColor: primaryTransitColor }}
          />
          <div className="article-subheader">
            <div className="article-details__wrap">
              <div className="article-details__byline">
                {partnerImage}
                <ul>
                  {publishDate && (
                    <li className="article-details__publish_date">
                      <span>{publishDate}</span>
                    </li>
                  )}
                  {authorName && (
                    <li className="article-details__author">By {authorName}</li>
                  )}
                </ul>
              </div>
            </div>
            {photoCredit && (
              <figcaption className="article-photo-credit">
                {photoCredit}
              </figcaption>
            )}
          </div>
        </div>
        <div className="article-content">
          <div className="article-content-inner">
            <div className="article-body">
              {post.body ? formatRichText(post.body.json) : ''}
            </div>
            <Offer offer={post.offer} />
            <Places places={post.places} />
          </div>
        </div>
        {subcategories?.length > 0 && (
          <LocationTags subcategories={subcategories} />
        )}
        {relatedPosts.length > 0 && (
          <div className="related-posts">
            <h2>{`More in ${post.categories?.[0].title}`}</h2>
            <ul className="article-list">
              {relatedPosts.map(article => (
                <li key={article.slug}>
                  <ArticlePreview article={article} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </article>
      <aside className="sidebar">
        <div className="sidebar-inner">
          {ads && ads.map(ad => <AdUnit key={ad.id} ad={ad} />)}
        </div>
      </aside>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      ...ArticleFragment
    }

    allArticles: allContentfulArticle(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...ArticleListFragment
          heroImage {
            title
            fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
              src
            }
          }
        }
      }
    }

    contentfulHomePage {
      ...ArticlesAdsFragment
      categories {
        slug
        featuredImage {
          fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
            src
          }
          fixed(width: 1180) {
            src
          }
        }
      }
    }
  }
`;
