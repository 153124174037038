import React from 'react';

import { LocationTag } from './location-tag';

export const LocationTags = ({ subcategories }) => (
  <div className="location-tags">
    <h3>Locations in this article</h3>
    <div className="location-tags--tags">
      {subcategories.map(({ slug, title }) => (
        <LocationTag key={slug} slug={slug} title={title} />
      ))}
    </div>
  </div>
);
