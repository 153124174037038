const displayUnapprovedEntries =
  process.env.GATSBY_DISPLAY_UNAPPROVED_ENTRIES === 'true';

// When the GATSBY_DISPLAY_UNAPPROVED_ENTRIES environment variable is set to
// "true," any entry passed in that has a `holdForApproval` property that is set
// to `true` will be filtered out. Useful for articles that need to be published
// to staging but not production.
module.exports = entries => {
  if (displayUnapprovedEntries) {
    return entries;
  }

  return entries.filter(e => !e.holdForApproval);
};
