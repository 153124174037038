import React from 'react';
import { Link } from 'gatsby';
import TransitBand from '../components/transit-band';
import fixAssetUrl from '../fix-asset-url';

export default ({ article, isHero = false }) => {
  if (!article) {
    return null;
  }

  const {
    //categories,
    subcategories,
    heroImage,
    title,
    slug,
    partnerLabel,
    partnerLogo,
  } = article;

  let tags = [];
  if (subcategories) {
    tags = [...subcategories];
  }
  // if (categories) {
  //   tags = [...tags, ...categories];
  // }

  const classList = ['article-list__link'];
  let showPartner = false;
  if (isHero && partnerLabel && partnerLogo) {
    showPartner = true;
    classList.push('article-list__isHero');
  }

  return (
    <Link className={classList.join(' ')} to={`/articles/${slug}`}>
      <img
        className="article-list__image"
        alt={heroImage?.title}
        src={fixAssetUrl(heroImage?.fluid?.src)}
      />
      <TransitBand article={article} />
      <div className="article-list__inner">
        <div>
          <ul className="article-list__tags">
            {tags && tags.map(tag => <li key={tag.slug}>{tag.title}</li>)}
          </ul>
          <h3 className="article-list__title">{title}</h3>
        </div>
        {showPartner && (
          <div className="article-list__partner">
            <span>{partnerLabel}</span>
            <div className="partner-logo">
              <img
                src={fixAssetUrl(partnerLogo.file?.url)}
                alt="Partner Logo"
              />
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};
