import { useEffect } from 'react';

export const useArticleProgress = () => {
  useEffect(() => {
    let stickyTitle, stickyArticleTitle;

    // adds back to top button to sticky title
    const addBackToTop = parent => {
      const backToTop = parent.appendChild(document.createElement('div'));
      backToTop.classList.add('back-to-top');
      backToTop.textContent = 'Back to top';

      const arrow = backToTop.appendChild(document.createElement('span'));
      arrow.classList.add('material-icons', 'md-18');
      arrow.textContent = 'arrow_upward';
      backToTop.addEventListener('click', e => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      });
    };

    const trackArticleProgress = () => {
      const article = document.querySelector('article');
      const title = document.querySelector('.article-title');
      const articleTitle = document.querySelector('.article-header__title');

      stickyTitle = document.querySelector('.article-title.sticky');
      stickyArticleTitle = document.querySelector(
        '.article-header__title.sticky'
      );

      let progress, sidebarInner, stickyTitleInner;

      if (!stickyTitle || !stickyArticleTitle) {
        if (title) {
          stickyTitle = title.cloneNode(true);
          stickyTitle.classList.add('sticky');
          stickyTitle.setAttribute('aria-hidden', true);

          sidebarInner = document.querySelector('.sidebar-inner');
          document.body.appendChild(stickyTitle);

          stickyTitleInner = document.querySelector(
            '.article-title.sticky .article-title__inner'
          );

          // remove a node present on event-page
          const ul = stickyTitle.querySelector('ul.event__logistics');
          if (ul) {
            stickyTitle.removeChild(ul);
          }
        } else if (articleTitle) {
          stickyArticleTitle = articleTitle.cloneNode(true);
          stickyArticleTitle.classList.add('sticky');
          stickyArticleTitle.setAttribute('aria-hidden', true);

          document.body.appendChild(stickyArticleTitle);
        }

        progress = document.querySelector('.sticky .article-progress');
        addBackToTop(stickyTitleInner || stickyArticleTitle);

        if (article && progress) {
          const updateProgress = () => {
            progress.style.width = `${
              100 * Math.min(1, window.pageYOffset / article.offsetHeight)
            }%`;

            const stickyVisible = window.pageYOffset > 350;

            if (stickyTitle) {
              stickyTitle.classList.toggle('visible', stickyVisible);
            }

            if (stickyArticleTitle) {
              stickyArticleTitle.classList.toggle('visible', stickyVisible);
            }

            if (sidebarInner) {
              sidebarInner.classList.toggle('offset', stickyVisible);
            }
          };

          updateProgress();
          setInterval(updateProgress, 100);
        }
      }
    };

    trackArticleProgress();

    return () => {
      if (stickyTitle?.parentElement) {
        stickyTitle.parentElement.removeChild(stickyTitle);
      }
      if (stickyArticleTitle?.parentElement) {
        stickyArticleTitle.parentElement.removeChild(stickyArticleTitle);
      }
    };
  }, []);
};
